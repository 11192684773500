import * as React from "react"
import { graphql } from 'gatsby'

import Section from "../components/section"
import PageHero from "../components/page-hero"
import Photos from "../components/photos"
import Layout from "../components/layout"

const PlanningPage = ({data}) => {

  const sections = data.allMdx && data.allMdx.edges.map(({node})=> <Section key={node.id} {...node} />)

  return (
    <Layout page="/planning">
      <header>
        <PageHero title="Planning"/>
      </header>
      <main className="main">
        {sections}
      </main>
    </Layout>
  )
}

export default PlanningPage

export const Head = () => <title>Ryan Gallagher & Lisa Hansen's Wedding</title>

export const query = graphql`
  query PlanningPageQuery {
    allMdx(
      filter: {fields: {source: {eq: "section"}}, frontmatter: {page: {eq: "planning"}}}
      sort: {order: ASC, fields: frontmatter___order}
    ) {
      edges {
        node {
          id
          fields {
            source,
            slug
          }
          frontmatter {
            graphic
            title
          }
          body
        }
      }
    }
  }
`
